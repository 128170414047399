@font-face {
	font-family: 'Inter';
	font-display: swap;
	src: local('Inter'), url('/fonts/Inter.ttf') format('truetype');
}

@font-face {
	font-family: 'Playfair Display';
	font-display: swap;
	src: local('Playfair Display'), local('PlayfairDisplay'),
		url('/fonts/PlayfairDisplay.ttf') format('truetype');
}

@font-face {
	font-family: 'Playfair Display';
	font-display: swap;
	font-style: italic;
	src: local('Playfair Display Italic'), local('PlayfairDisplay Italic'),
		local('PlayfairDisplay-Italic'),
		url('/fonts/PlayfairDisplay-Italic.ttf') format('truetype');
}

// @font-face {
// 	font-family: 'Gobold Uplow';
// 	font-weight: normal;
// 	font-style: normal;
// 	font-display: swap;
// 	src: local('Gobold Uplow'), local('GoboldUplow'), local('Gobold-Uplow'),
// 		url('/fonts/Gobold-Uplow.otf') format('opentype');
// }
